import React from "react"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import containerClasses from "../styles/container.module.css"
import { Link } from "gatsby"
import { ArrowRight } from "react-feather"
import buttons from "../styles/buttons.module.css"

const Success = () => (
  <Layout>
    <SEO title="Success" />
    <div className={`${containerClasses.container} py-30`}>
      <div className="col-span-4 md:col-span-8 lg:col-span-8">
        <h1 className="mt-4 mb-4 text-left text-blackNew fluid-heading-05">
          Success!
        </h1>
        <p>
          Thank you for your enquiry, which we can confirm has been successfully
          received. We will respond to you shortly by email at &nbsp;
          <a
            href="mailto:clinic@drelizabethhawkes.com"
            aria-label="Email clinic@drelizabethhawkes.com"
          >
            clinic@drelizabethhawkes.com
          </a>
        </p>
        <p>
          We recommend you also keep a watch on your spam folder in case the
          email is accidentally placed there by the mail server.
        </p>
        <Link
          className={`text-center w-full block mt-4 mb-8 lg:inline-block md:mr-1 lg:w-auto ${buttons.btnLargeExpressive}`}
          to="/"
        >
          <div className="flex items-center justify-between">
            Home
            <ArrowRight className="ml-10" size="16" />
          </div>
        </Link>
      </div>
    </div>
  </Layout>
)

export default Success
